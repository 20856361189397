"use client";
import React, { createContext, useContext, useMemo, useState } from "react";

import { Frequency, Currency, PricingSectionTranslations } from "./types";

import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";

import { Locale } from "i18n-config";
import { localeToCurrency } from "utils/stripe";

export type PlansContextProps = {
  children: React.ReactNode;
  defaultFrequency?: Frequency["value"];
  defaultCurrency?: Currency;
  locale: Locale;
  translation: TranslationProp<PricingSectionTranslations>;
};

export type CurrencyOption = {
  value: Currency;
  label: Currency;
};

export type PlansContextContextData = {
  frequency: Frequency;
  frequencies: Frequency[];
  setFrequency: React.Dispatch<React.SetStateAction<Frequency>>;

  currencies: CurrencyOption[];
  currency: CurrencyOption;

  setCurrency: React.Dispatch<React.SetStateAction<CurrencyOption>>;
};

const PlansContextContext = createContext<PlansContextContextData>(
  {} as PlansContextContextData
);

export const PlansContext = ({
  children,
  defaultFrequency,
  locale,
  translation,
  defaultCurrency: defaultCurrencyProp,
}: PlansContextProps) => {
  const { t } = useTranslation({ translation });

  const frequencies = useMemo<Frequency[]>(
    () => [
      { value: "month", label: t("pricingSection.frequencies.monthly") },
      { value: "year", label: t("pricingSection.frequencies.annually") },
    ],
    [t]
  );

  const currencies = useMemo<CurrencyOption[]>(
    () => [
      { value: "brl", label: "brl" },
      { value: "usd", label: "usd" },
    ],
    []
  );

  const [frequency, setFrequency] = useState<Frequency>(() => {
    if (defaultFrequency) {
      return frequencies.find(
        (frequency) => frequency.value === defaultFrequency
      )!;
    }
    return frequencies[0];
  });
  const [currency, setCurrency] = useState<CurrencyOption>(() => {
    const defaultCurrency = defaultCurrencyProp ?? localeToCurrency(locale);
    if (defaultCurrency) {
      return currencies.find((currency) => currency.value === defaultCurrency)!;
    }
    return currencies[0];
  });

  return (
    <PlansContextContext.Provider
      value={{
        frequency,
        setFrequency,
        currency,
        setCurrency,
        frequencies,
        currencies,
      }}
    >
      {children}
    </PlansContextContext.Provider>
  );
};

export function usePlansContext() {
  const context = useContext(PlansContextContext);

  if (!context) {
    throw new Error("usePlansContext must be used within a PlansContext");
  }

  return context;
}
