"use client";
import { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import Link from "next/link";
import MenuIcon from "@heroicons/react/outline/MenuIcon";

import { SECTION_IDS } from "modules/lp/contants";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { cn } from "@irbano/react-components/dist/lib/utils";

import { HeaderTranslations } from "./types";

import { Button } from "@irbano/react-components/dist/components/ui/button";
import { useLanguageSwitch } from "app/[lang]/hooks/useChangeLanguage";

const MobileHeaderSidebar = dynamic(() => import("./mobile-header-sidebar"));

export type DesktopLinkProps = {
  text: string;
  href: string;
};
export const DesktopLink = ({ text, href }: DesktopLinkProps) => (
  <Link
    className="w-fit text-sm lg:text-base font-semibold"
    href={href}
    scroll={true}
  >
    {text}
  </Link>
);

export type HeaderProps = {
  translation: TranslationProp<HeaderTranslations>;
};
export const Header = ({ translation }: HeaderProps) => {
  const { t } = useTranslation({ translation });

  const { languageOptions, changeLanguage } = useLanguageSwitch();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggleSideBar = useCallback(() => {
    setIsSideBarOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const onResize = (event: UIEvent) => {
      const target: Window = event.target as any;
      const desktopBreakpoint = 768;

      if (
        target &&
        isSideBarOpen &&
        target.innerWidth !== undefined &&
        target.innerWidth > desktopBreakpoint
      ) {
        setIsSideBarOpen(false);
      }
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [isSideBarOpen]);

  return (
    <>
      <header className="w-full px-2 md:px-8 flex shadow-[12px_rgba(0,0,0,0.3)] justify-center items-center text-xs md:text-base">
        <div className="w-full md:w-[75%] px-4 rounded-[12px] lg:bg-gray-100 my-4 max-w-5xl flex justify-between items-center gap-4">
          <div className="w-fit flex items-center gap-4 ">
            <Button
              id="open-mobile-sidebar-button"
              className="w-fit lg:hidden"
              onClick={toggleSideBar}
              variant="ghost"
              aria-label="Open sidebar"
            >
              <MenuIcon className="w- h-6 text-secondary-700 text-lg" />
            </Button>

            <div className="hidden lg:block w-[100px] h-[56.25px] lg:w-[100px] lg:h-[58.75px] relative">
              <Image
                src="/logos/only-name.png"
                alt="Redilify"
                fill
                objectFit="contain"
                className="absolute"
                priority={true}
              />
            </div>
          </div>

          <nav className="w-full hidden lg:flex  text-sm justify-center items-center gap-8 md:py-4">
            <DesktopLink
              text={t("topbar.items.home")}
              href={`/#${SECTION_IDS.home}`}
            />
            <DesktopLink text={t("topbar.items.plans")} href={`/plans`} />
            <DesktopLink
              text={t("topbar.items.faq")}
              href={`/#${SECTION_IDS.faq}`}
            />
          </nav>

          <div className=" hidden lg:flex items-center space-x-4 text-sm font-semibold text-nowrap">
            <Link
              className="hover:text-primary hover:scale-105 transition-all delay-100"
              href="/auth/signin"
              id="topbar-sign-in-button"
            >
              {t("topbar.signIn")}
            </Link>
            <span>|</span>
            <Link
              className="hover:text-primary hover:scale-105 transition-all delay-100"
              href="/plans"
              id="topbar-see-plans-button"
            >
              {t("topbar.seePlans")}
            </Link>
          </div>
        </div>

        <div className="flex text-nowrap text-sm font-bold uppercase lg:absolute right-9">
          {languageOptions.map((language, index) => (
            <button
              key={`Language_option_${language.value}`}
              onClick={() => changeLanguage(language.value)}
            >
              {language.shortLabel}
              {index !== languageOptions.length - 1 && (
                <span className="mx-1 cursor-text hover:text-black">|</span>
              )}
            </button>
          ))}
        </div>
      </header>

      <div
        className={cn(
          "w-full h-screen fixed top-0 right-0 bottom-0 left-0 bg-black opacity-30 cursor-pointer z-30",
          `${isSideBarOpen ? "block lg:hidden" : "hidden"}`
        )}
        onClick={toggleSideBar}
      />

      <MobileHeaderSidebar
        translation={translation}
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
      />
    </>
  );
};
