import { Locale } from "i18n-config";

const articles = {
  aboutUs: "8983175",
  ourMissionAndValues: "8983180",
  terms: "8983070",
  policy: "9068885",
  socialMediaPreview: "8983143",
  tagManagerAndPixel: "8983122",
  deepLink: "8983093",
  customDomain: "8983103",
} as const;

export const getIntercomHelpCenterUrl = (locale: Locale) => {
  return `https://help.redilify.com/${localeToIntercomLanguage(locale)}`;
};

export const getIntercomArticleUrl = (
  key: keyof typeof articles,
  locale: Locale
) => {
  const article = articles[key];

  return `${getIntercomHelpCenterUrl(locale)}/articles/${article}`;
};

export const localeToIntercomLanguage = (locale: Locale) => {
  if (locale === "pt-BR") {
    return "pt-BR";
  }

  if (locale === "en-US") {
    return "en";
  }

  return "en";
};
