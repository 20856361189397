"use client";
import { useMemo, useCallback } from "react";
import clsx from "clsx";

import { PLANS_MAP, FeatureName } from "@irbano/redilify-domain";

import { CheckIcon } from "@heroicons/react/solid";
import {
  RadioGroup,
  RadioGroupItem,
} from "@irbano/react-components/dist/components/ui/radio-group";

import { Label } from "@irbano/react-components/dist/components/ui/label";

import { InfoPopover } from "@irbano/react-components/dist/components/info-popover";

import { SECTION_IDS } from "modules/lp/contants";
import type { Price, Prices } from "server/utils/stripe";
import { PlansCTAButtonProps, PricingSectionTranslations, Tier } from "./types";
import { FeatureLimitComponent } from "./FeatureLimitComponent";
import { LoggedOutChoosePlanButton } from "./LoggedOutChoosePlanButton";
import {
  PlansContext,
  PlansContextProps,
  usePlansContext,
} from "./PlansContext";
import { LoggedInChoosePlanButton } from "./LoggedInChoosePlanButton";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { Locale } from "i18n-config";
import { POSTHOG_EVENTS } from "shared/constants/analytics";
import { usePostHog } from "posthog-js/react";

import { CurrentPlan, CurrentPlanProps } from "modules/current-plan";
import { MoveDown } from "lucide-react";

export type PricingSectionProps = {
  planPrices: Prices;
  /**
   * Should be PricingSection.LoggedOutChoosePlanButton or PricingSection.LoggedInChoosePlanButton
   */
  CTAButton: (props: PlansCTAButtonProps) => React.ReactNode;
  translation: TranslationProp<PricingSectionTranslations>;
  locale: Locale;
  UserCurrentPlan?: () => React.ReactNode;
} & Pick<PlansContextProps, "locale" | "defaultFrequency" | "defaultCurrency">;

function InnerPricingSection({
  planPrices,
  CTAButton,
  translation,
  locale,
  UserCurrentPlan,
}: PricingSectionProps) {
  const { t } = useTranslation({ translation });
  const posthog = usePostHog();

  const {
    currency,
    frequency,
    setFrequency,
    frequencies,
    currencies,
    setCurrency,
  } = usePlansContext();

  const formatPrice = useCallback(
    (price: number) => {
      const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency.value.toUpperCase(),
      });

      return formatter.format(price);
    },
    [currency, locale]
  );

  const getPrice = useCallback(
    (price?: Price) => {
      if (!price) {
        return {
          originalPrice: "-",
          isFree: true,
          priceWithDiscount: "0",
          coupon: null,
        };
      }

      return {
        originalPrice: formatPrice(price.value),
        isFree: price.value === 0,
        priceWithDiscount: price.discount
          ? formatPrice(price.value - price.value * price.discount.percentage)
          : null,
        coupon: price.discount?.coupon ?? null,
        discountPercentage: price?.discount?.percentage,
      };
    },
    [formatPrice]
  );

  const tiers = useMemo<Tier[]>(
    () => [
      {
        name: "free",
        label: t("pricingSection.plans.free.label"),
        featured: false,
        prices: planPrices.free,
        mainFeatures: [
          t("pricingSection.plans.free.mainFeatures.customShortId"),
          t("pricingSection.plans.free.mainFeatures.editLinkDestinations"),
        ],
      },
      {
        name: "premium",
        label: t("pricingSection.plans.premium.label"),
        featured: true,
        prices: planPrices.premium,
        mainFeatures: [
          t("pricingSection.plans.premium.mainFeatures.customDomain"),
          t("pricingSection.plans.premium.mainFeatures.openGraph"),
          t("pricingSection.plans.premium.mainFeatures.utm"),
        ],
      },
      {
        name: "pro",
        label: t("pricingSection.plans.pro.label"),
        featured: false,
        prices: planPrices.pro,
        mainFeatures: [t("pricingSection.plans.pro.mainFeatures.deepLink")],
      },
      {
        name: "business",
        label: t("pricingSection.plans.business.label"),
        featured: false,
        prices: planPrices.business,
        mainFeatures: [t("pricingSection.plans.business.mainFeatures.scale")],
      },
    ],
    [
      planPrices.business,
      planPrices.free,
      planPrices.premium,
      planPrices.pro,
      t,
    ]
  );

  const sections: {
    label: string;
    features: {
      name: string;
      featureName: FeatureName;
      label: string;
      helpText: string;
    }[];
  }[] = useMemo(
    () => [
      {
        label: t("pricingSection.sections.linkManagement.label"),
        features: [
          {
            name: "links",
            featureName: "links",
            label: t("features.links.title"),
            helpText: t("features.links.description"),
          },
          {
            name: "editLinkDestinations",
            featureName: "editLinkDestinations",
            label: t("features.editLinkDestinations.title"),
            helpText: t("features.editLinkDestinations.description"),
          },
          {
            name: "destinationsPerLink",
            featureName: "destinationsPerLink",
            label: t("features.destinationsPerLink.title"),
            helpText: t("features.destinationsPerLink.description"),
          },
          {
            name: "customDomains",
            featureName: "customDomains",
            label: t("features.customDomains.title"),
            helpText: t("features.customDomains.description"),
          },
          {
            name: "shareWithCustomDomain",
            featureName: "shareWithCustomDomain",
            label: t("features.shareWithCustomDomain.title"),
            helpText: t("features.shareWithCustomDomain.description"),
          },
          {
            name: "linkGroups",
            featureName: "linkGroups",
            label: t("features.linkGroups.title"),
            helpText: t("features.linkGroups.description"),
          },
          {
            name: "customShortId",
            featureName: "customShortId",
            label: t("features.customShortId.title"),
            helpText: t("features.customShortId.description"),
          },
          {
            name: "deepLink",
            featureName: "deepLink",
            label: t("features.deepLink.title"),
            helpText: t("features.deepLink.description"),
          },
          {
            name: "utmBuilder",
            featureName: "utmBuilder",
            label: t("features.utmBuilder.title"),
            helpText: t("features.utmBuilder.description"),
          },
          {
            name: "queryParamsProxy",
            featureName: "queryParamsProxy",
            label: t("features.queryParamsProxy.title"),
            helpText: t("features.queryParamsProxy.description"),
          },
          {
            name: "linkExpiration",
            featureName: "linkExpiration",
            label: t("features.linkExpiration.title"),
            helpText: t("features.linkExpiration.description"),
          },
          {
            name: "openGraph",
            featureName: "openGraph",
            label: t("features.openGraph.title"),
            helpText: t("features.openGraph.description"),
          },
          {
            name: "facebookPixel",
            featureName: "facebookPixel",
            label: t("features.facebookPixel.title"),
            helpText: t("features.facebookPixel.description"),
          },
          {
            name: "gtm",
            featureName: "gtm",
            label: t("features.gtm.title"),
            helpText: t("features.gtm.description"),
          },
          {
            name: "shareWithQRCode",
            featureName: "shareWithQRCode",
            label: t("features.shareWithQRCode.title"),
            helpText: t("features.shareWithQRCode.description"),
          },
        ],
      },
      {
        label: t("pricingSection.sections.workspaces.label"),
        features: [
          {
            name: "workspaces",
            featureName: "workspaces",
            label: t("features.workspaces.title"),
            helpText: t("features.workspaces.description"),
          },
          {
            name: "usersPerWorkspace",
            featureName: "usersPerWorkspace",
            label: t("features.usersPerWorkspace.title"),
            helpText: t("features.usersPerWorkspace.description"),
          },
        ],
      },
      {
        label: t("pricingSection.sections.linkInBio.label"),
        features: [
          {
            name: "linkInBio",
            featureName: "linkInBio",
            label: t("features.linkInBio.title"),
            helpText: t("features.linkInBio.description"),
          },
          {
            name: "linkInBioCustomBranding",
            featureName: "linkInBioCustomBranding",
            label: t("features.linkInBioCustomBranding.title"),
            helpText: t("features.linkInBioCustomBranding.description"),
          },
        ],
      },
      {
        label: t("pricingSection.sections.linkAnalytics.label"),
        features: [
          {
            name: "linkAnalytics",
            featureName: "linkAnalytics",
            label: t("features.linkAnalytics.title"),
            helpText: t("features.linkAnalytics.description"),
          },
          {
            name: "linkClicksRetention",
            featureName: "linkClicksRetention",
            label: t("features.linkClicksRetention.title"),
            helpText: t("features.linkClicksRetention.description"),
          },
          {
            name: "linkAnalyticsCustomRange",
            featureName: "linkAnalyticsCustomRange",
            label: t("features.linkAnalyticsCustomRange.title"),
            helpText: t("features.linkAnalyticsCustomRange.description"),
          },
          {
            name: "linkAnalyticsGeolocationMetrics",
            featureName: "linkAnalyticsGeolocationMetrics",
            label: t("features.linkAnalyticsGeolocationMetrics.title"),
            helpText: t("features.linkAnalyticsGeolocationMetrics.description"),
          },
          {
            name: "linkAnalyticsOSMetrics",
            featureName: "linkAnalyticsOSMetrics",
            label: t("features.linkAnalyticsOSMetrics.title"),
            helpText: t("features.linkAnalyticsOSMetrics.description"),
          },
          {
            name: "linkAnalyticsUTMMetrics",
            featureName: "linkAnalyticsUTMMetrics",
            label: t("features.linkAnalyticsUTMMetrics.title"),
            helpText: t("features.linkAnalyticsUTMMetrics.description"),
          },
        ],
      },
    ],
    [t]
  );

  return (
    <div id={SECTION_IDS.plans} className="w-full isolate overflow-hidden">
      <div className="flow-root bg-secondary-900 pb-16 pt-24 sm:pt-32 lg:pb-0">
        <div className="mx-auto max-w-screen-2xl px-6 lg:px-8">
          {UserCurrentPlan ? (
            <UserCurrentPlan />
          ) : (
            <div className="relative z-10">
              <h2 className="mx-auto max-w-4xl text-center text-2xl lg:text-4xl font-bold tracking-tight text-white">
                {t("pricingSection.title")}
              </h2>
              <div className="flex flex-col">
                <p className="mx-auto mt-4 max-w-2xl text-center text-[0.9rem] lg:text-lg leading-8 text-white/60">
                  {t("pricingSection.subTitle")}
                </p>
                <p className="mx-auto max-w-2xl text-center text-[0.9rem] lg:text-lg leading-8 text-white/60">
                  {t("pricingSection.subTitle2")}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 items-start md:items-center md:justify-center w-full md:w-1/2 md:space-x-8 mt-16 bg-white bg-opacity-90 p-4 rounded-md shadow-lg">
                  <div className="flex justify-start">
                    <RadioGroup
                      className="flex items-center"
                      defaultValue={frequency.value || "month"}
                      onValueChange={(str) =>
                        setFrequency(
                          frequencies.find(({ value }) => value === str)!
                        )
                      }
                    >
                      {frequencies.map((option, index) => (
                        <div
                          key={`frequency_${option.value}_${index}`}
                          className="flex items-center space-x-2"
                        >
                          <RadioGroupItem
                            className="text-primary-500"
                            value={option.value}
                            id="r1"
                          />
                          <Label className="font-semibold " htmlFor="r1">
                            {option.label}
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </div>
                  <span className="hidden md:block font-semibold">|</span>
                  <div className="flex justify-start ">
                    <RadioGroup
                      className="flex items-center"
                      defaultValue={currency.value || "usd"}
                      onValueChange={(str) =>
                        setCurrency(
                          currencies.find(({ value }) => value === str)!
                        )
                      }
                    >
                      {currencies.map((option, index) => (
                        <div
                          key={`currency_${option.value}_${index}`}
                          className="flex items-center space-x-2"
                        >
                          <RadioGroupItem
                            className="text-primary-500"
                            value={option.value}
                            id="r1"
                          />
                          <Label className="font-semibold " htmlFor="r1">
                            {option.label.toUpperCase()}
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-4">
            <svg
              viewBox="0 0 1208 1024"
              aria-hidden="true"
              className="absolute -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                  <stop stopColor="#1d1d1b" />
                  <stop offset={1} stopColor="#fcc755" />
                </radialGradient>
              </defs>
            </svg>
            <div
              className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
              aria-hidden="true"
            />
            {tiers.map((tier) => {
              const {
                originalPrice,
                priceWithDiscount,
                isFree,
                discountPercentage,
              } = getPrice(tier.prices?.[frequency.value]?.[currency.value]);
              return (
                <div
                  key={`Tier_${tier.name}`}
                  className={clsx(
                    tier.featured
                      ? "z-10 bg-white shadow-xl ring-1 ring-gray-900/10"
                      : "bg-gray-800/80 ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0",
                    "relative rounded-2xl"
                  )}
                >
                  <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                    <h3
                      id={tier.name}
                      className={clsx(
                        tier.featured ? "text-gray-900" : "text-white",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.label}
                    </h3>
                    <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                      <div className="mt-2 flex items-center gap-x-4 flex-wrap">
                        <div className="flex flex-col">
                          {tier.name !== "free" && priceWithDiscount && (
                            <div className="flex items-center space-x-4 py-2">
                              <span
                                className={clsx(
                                  "text-gray-300",
                                  "text-xl font-medium tracking-tight line-through"
                                )}
                              >
                                {originalPrice}
                              </span>
                              <div className="flex items-center justify-center bg-green-100 p-1 rounded-md">
                                <span
                                  className={clsx(
                                    "text-[1.1rem] font-semibold text-green-500 tracking-tight"
                                  )}
                                >
                                  -{" "}
                                  {discountPercentage &&
                                    discountPercentage * 100}
                                  %
                                </span>
                                <MoveDown
                                  size={16}
                                  className="text-green-500"
                                />
                              </div>
                            </div>
                          )}

                          <p
                            className={clsx(
                              tier.featured ? "text-gray-900" : "text-white",
                              "text-4xl font-bold tracking-tight"
                            )}
                          >
                            {priceWithDiscount ?? originalPrice}
                          </p>
                        </div>
                        <div className="text-sm leading-5">
                          {!isFree && (
                            <p
                              className={
                                tier.featured
                                  ? "text-gray-500"
                                  : "text-gray-400"
                              }
                            >
                              {t(`pricingSection.chargedBy.${frequency.value}`)}
                            </p>
                          )}
                        </div>
                      </div>
                      <CTAButton tier={tier} translation={translation as any} />
                    </div>

                    <div className="mt-8 flow-root sm:mt-10">
                      <ul
                        role="list"
                        className={clsx(
                          tier.featured
                            ? "divide-gray-900/5 border-gray-900/5 text-gray-600"
                            : "divide-white/5 border-white/5 text-white",
                          "-my-2 divide-y border-t text-sm leading-6 lg:border-t-0"
                        )}
                      >
                        {tier.mainFeatures.map((mainFeature) => (
                          <li key={mainFeature} className="flex gap-x-3 py-2">
                            <CheckIcon
                              className={clsx(
                                tier.featured
                                  ? "text-primary-600"
                                  : "text-gray-500",
                                "h-6 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                            {mainFeature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="relative bg-gray-50 lg:pt-14">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          {/* Feature comparison (up to lg) */}
          <section
            aria-labelledby="mobile-comparison-heading"
            className="lg:hidden"
          >
            <h2 id="mobile-comparison-heading" className="sr-only">
              {t("pricingSection.featureComparison.title")}
            </h2>

            <div className="mx-auto max-w-2xl space-y-16">
              {tiers.map((tier) => (
                <div
                  key={`Tier_${tier.name}`}
                  className="border-t border-gray-900/10"
                >
                  <div
                    className={clsx(
                      tier.featured
                        ? "border-primary-600"
                        : "border-transparent",
                      "-mt-px w-72 border-t-2 pt-10 md:w-80"
                    )}
                  >
                    <h3
                      className={clsx(
                        tier.featured ? "text-primary-600" : "text-gray-900",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.label}
                    </h3>
                  </div>

                  <div className="mt-10 space-y-10">
                    {sections.map((section) => (
                      <div key={section.label}>
                        <h4 className="text-sm font-semibold leading-6 text-gray-900">
                          {section.label}
                        </h4>
                        <div className="relative mt-6">
                          {/* Fake card background */}
                          <div
                            aria-hidden="true"
                            className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                          />

                          <div
                            className={clsx(
                              tier.featured
                                ? "ring-2 ring-primary-600"
                                : "ring-1 ring-gray-900/10",
                              "relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0"
                            )}
                          >
                            <dl className="divide-y divide-gray-200 text-sm leading-6">
                              {section.features.map((feature) => (
                                <div
                                  key={feature.label}
                                  className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                >
                                  <dt className="flex items-center justify-center pr-4 text-gray-600">
                                    {feature.label}
                                    {feature.helpText && (
                                      <div className="ml-2">
                                        <InfoPopover
                                          id={`pricing-section-${feature.name}-info-button`}
                                          content={feature.helpText}
                                          onOpenChange={(isOpen) => {
                                            if (isOpen) {
                                              posthog.capture(
                                                POSTHOG_EVENTS.planFeatureInfoPopupOpened,
                                                {
                                                  featureName:
                                                    feature.featureName,
                                                  planName: tier.name,
                                                }
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                  </dt>
                                  <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                    <FeatureLimitComponent
                                      translation={translation}
                                      featured={tier.featured}
                                      limit={
                                        PLANS_MAP[tier.name]["features"][
                                          feature.featureName
                                        ].limit
                                      }
                                      featureName={""}
                                    />
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>

                          {/* Fake card border */}
                          <div
                            aria-hidden="true"
                            className={clsx(
                              tier.featured
                                ? "ring-2 ring-primary-600"
                                : "ring-1 ring-gray-900/10",
                              "pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block"
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Feature comparison (lg+) */}
          <section
            aria-labelledby="comparison-heading"
            className="hidden lg:block"
          >
            <h2 id="comparison-heading" className="sr-only">
              {t("pricingSection.featureComparison.title")}
            </h2>

            <div className="grid grid-cols-5 gap-x-8 border-t border-gray-900/10 before:block">
              {tiers.map((tier) => (
                <div
                  key={`Tier_${tier.name}`}
                  aria-hidden="true"
                  className="-mt-px"
                >
                  <div
                    className={clsx(
                      tier.featured
                        ? "border-primary-600"
                        : "border-transparent",
                      "border-t-2 pt-10"
                    )}
                  >
                    <p
                      className={clsx(
                        tier.featured ? "text-primary-600" : "text-gray-900",
                        "text-sm font-semibold leading-6"
                      )}
                    >
                      {tier.label}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="-mt-6 space-y-16">
              {sections.map((section) => (
                <div key={section.label}>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    {section.label}
                  </h3>
                  <div className="relative -mx-8 mt-10">
                    {/* Fake card backgrounds */}
                    <div
                      className="absolute inset-x-8 inset-y-0 grid grid-cols-5 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      {tiers.map((tier) => (
                        <div
                          key={`Tier_limit_background_${tier.name}`}
                          className="h-full w-full rounded-lg bg-white shadow-sm"
                        />
                      ))}
                    </div>

                    <table className="relative w-full border-separate border-spacing-x-8">
                      <thead>
                        <tr className="text-left">
                          <th scope="col">
                            <span className="sr-only">Feature</span>
                          </th>
                          {tiers.map((tier) => (
                            <th key={`Tier_${tier.name}`} scope="col">
                              <span className="sr-only">{tier.label} tier</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {section.features.map((feature, featureIdx) => (
                          <tr key={feature.label}>
                            <th
                              scope="row"
                              className="w-1/5 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                            >
                              <span className="flex justify-start items-center">
                                {feature.label}
                                {feature.helpText && (
                                  <div className="ml-2">
                                    <InfoPopover
                                      content={feature.helpText}
                                      onOpenChange={(isOpen) => {
                                        if (isOpen) {
                                          posthog.capture(
                                            POSTHOG_EVENTS.planFeatureInfoPopupOpened,
                                            {
                                              featureName: feature.featureName,
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </span>
                              {featureIdx !== section.features.length - 1 ? (
                                <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                              ) : null}
                            </th>
                            {tiers.map((tier) => (
                              <td
                                key={`Tier_${tier.name}`}
                                className="relative w-1/5 px-4 py-0 text-center"
                              >
                                <span className="relative h-full w-full py-3">
                                  <FeatureLimitComponent
                                    translation={translation}
                                    featured={tier.featured}
                                    limit={
                                      PLANS_MAP[tier.name]["features"][
                                        feature.featureName
                                      ].limit
                                    }
                                    featureName={feature.featureName}
                                  />
                                </span>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Fake card borders */}
                    <div
                      className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-5 gap-x-8 before:block"
                      aria-hidden="true"
                    >
                      {tiers.map((tier) => (
                        <div
                          key={`Tier_${tier.name}`}
                          className={clsx(
                            tier.featured
                              ? "ring-2 ring-primary-600"
                              : "ring-1 ring-gray-900/10",
                            "rounded-lg"
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export function PricingSection({
  locale,
  defaultFrequency,
  defaultCurrency,
  ...props
}: PricingSectionProps) {
  return (
    <PlansContext
      defaultFrequency={defaultFrequency}
      defaultCurrency={defaultCurrency}
      locale={locale}
      translation={props.translation}
    >
      <InnerPricingSection locale={locale} {...props} />
    </PlansContext>
  );
}

const PricingSectionCurrentPlan = (props: CurrentPlanProps) => {
  return <CurrentPlan {...props} />;
};

export function PricingSectionLoggedOut(
  props: Omit<PricingSectionProps, "CTAButton">
) {
  return (
    <PricingSection
      {...props}
      CTAButton={PricingSection.LoggedOutChoosePlanButton}
    />
  );
}

export function PricingSectionLoggedOutLoading(
  props: Omit<PricingSectionProps, "CTAButton" | "planPrices">
) {
  return (
    <PricingSection
      {...props}
      planPrices={{
        free: {
          month: {},
          year: {},
        },
        business: {
          month: {},
          year: {},
        },
        premium: {
          month: {},
          year: {},
        },
        pro: {
          month: {},
          year: {},
        },
      }}
      CTAButton={PricingSection.LoggedOutChoosePlanButton}
    />
  );
}
PricingSection.LoggedOutChoosePlanButton = LoggedOutChoosePlanButton;
PricingSection.LoggedInChoosePlanButton = LoggedInChoosePlanButton;
PricingSection.CurrentPlan = PricingSectionCurrentPlan;
