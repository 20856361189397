"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AuthorizationServiceFactory: () => AuthorizationServiceFactory,
  PRIVILEGES: () => PRIVILEGES
});
module.exports = __toCommonJS(src_exports);

// src/privileges/index.ts
var Privilege = class {
  constructor({ type, action }) {
    this.type = type;
    this.action = action;
  }
};
var createPrivilege = (type) => {
  return new Privilege({ type, action: type });
};
var PRIVILEGES = {
  createRedirect: createPrivilege("createRedirect"),
  canManageRedirectDestinations: createPrivilege(
    "canManageRedirectDestinations"
  ),
  manageRedirectDestinations: createPrivilege("manageRedirectDestinations"),
  createCustomDomain: createPrivilege("createCustomDomain"),
  createRedirectGroup: createPrivilege("createRedirectGroup"),
  createTag: createPrivilege("createTag"),
  createWorkspace: createPrivilege("createWorkspace"),
  inviteWorkspaceUser: createPrivilege("inviteWorkspaceUser"),
  useWorkspace: createPrivilege("useWorkspace"),
  deleteWorkspace: createPrivilege("deleteWorkspace"),
  customShortId: createPrivilege("customShortId"),
  deepLink: createPrivilege("deepLink"),
  queryParamsProxy: createPrivilege("queryParamsProxy"),
  utmBuilder: createPrivilege("utmBuilder"),
  linkExpiration: createPrivilege("linkExpiration"),
  openGraph: createPrivilege("openGraph"),
  facebookPixel: createPrivilege("facebookPixel"),
  gtm: createPrivilege("gtm"),
  createLinkInBio: createPrivilege("createLinkInBio"),
  linkInBioCustomBranding: createPrivilege("linkInBioCustomBranding"),
  shareWithQRCode: createPrivilege("shareWithQRCode"),
  shareWithCustomDomain: createPrivilege("shareWithCustomDomain"),
  linkAnalytics: createPrivilege("linkAnalytics"),
  linkAnalyticsCustomRange: createPrivilege("linkAnalyticsCustomRange"),
  linkAnalyticsGeolocationMetrics: createPrivilege(
    "linkAnalyticsGeolocationMetrics"
  ),
  linkAnalyticsOSMetrics: createPrivilege("linkAnalyticsOSMetrics"),
  linkAnalyticsUTMMetrics: createPrivilege("linkAnalyticsUTMMetrics"),
  // User Management permissions
  canAccessStripeBillingPortal: createPrivilege("canAccessStripeBillingPortal"),
  // Admin only permissions
  impersonateUser: createPrivilege("impersonateUser")
};

// src/casl/authorizationService.ts
var import_ability3 = require("@casl/ability");

// src/casl/ability.ts
var import_ability = require("@casl/ability");
var getAbilityBuilder = () => new import_ability.AbilityBuilder(import_ability.createMongoAbility);

// src/casl/subscriptions/index.tsx
var getAuthRules = ({
  user,
  workspace,
  workspacesWithAccess,
  userPlanUsages
}) => {
  const builder = getAbilityBuilder();
  const canByPrivilege = (privilege, conditions) => builder.can(privilege.action, privilege.type, conditions);
  const cannotByPrivilege = (privilege, conditions) => builder.cannot(privilege.action, privilege.type, conditions);
  cannotByPrivilege(PRIVILEGES.impersonateUser, {});
  canByPrivilege(PRIVILEGES.canAccessStripeBillingPortal, { userId: user.id });
  const workspaceFilter = {
    workspaceId: {
      $eq: workspace.id
    }
  };
  canByPrivilege(PRIVILEGES.createRedirect, __spreadValues({
    createdOnLastMonth: {
      $lt: userPlanUsages.links.limit.value
    }
  }, workspaceFilter));
  if (!!userPlanUsages.editLinkDestinations.limit.value) {
    canByPrivilege(PRIVILEGES.canManageRedirectDestinations, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.canManageRedirectDestinations, __spreadValues({}, workspaceFilter));
  }
  canByPrivilege(PRIVILEGES.manageRedirectDestinations, __spreadValues({
    destinations: {
      $lte: userPlanUsages.destinationsPerLink.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.createCustomDomain, __spreadValues({
    alreadyCreated: {
      $lt: userPlanUsages.customDomains.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.createRedirectGroup, __spreadValues({
    createdOnLastMonth: {
      $lt: userPlanUsages.linkGroups.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.createTag, __spreadValues({
    alreadyCreated: {
      $lt: userPlanUsages.tags.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.createWorkspace, {
    alreadyCreated: {
      $lt: userPlanUsages.workspaces.limit.value
    },
    ownerId: {
      $eq: user.id
    },
    workspaceUsers: {
      $lte: userPlanUsages.usersPerWorkspace.limit.value
    }
  });
  canByPrivilege(PRIVILEGES.inviteWorkspaceUser, __spreadValues({
    users: {
      $lte: userPlanUsages.usersPerWorkspace.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.useWorkspace, {
    workspaceId: {
      $in: [workspace.id, ...workspacesWithAccess.map(({ id }) => id)]
    }
  });
  if (workspace.ownerId === user.id) {
    canByPrivilege(PRIVILEGES.deleteWorkspace, workspaceFilter);
  }
  if (!!userPlanUsages.customShortId.limit.value) {
    canByPrivilege(PRIVILEGES.customShortId, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.customShortId, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.deepLink.limit.value) {
    canByPrivilege(PRIVILEGES.deepLink, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.deepLink, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.queryParamsProxy.limit.value) {
    canByPrivilege(PRIVILEGES.queryParamsProxy, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.queryParamsProxy, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.utmBuilder.limit.value) {
    canByPrivilege(PRIVILEGES.utmBuilder, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.utmBuilder, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.linkExpiration.limit.value) {
    canByPrivilege(PRIVILEGES.linkExpiration, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkExpiration, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.openGraph.limit.value) {
    canByPrivilege(PRIVILEGES.openGraph, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.openGraph, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.facebookPixel.limit.value) {
    canByPrivilege(PRIVILEGES.facebookPixel, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.facebookPixel, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.gtm.limit.value) {
    canByPrivilege(PRIVILEGES.gtm, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.gtm, __spreadValues({}, workspaceFilter));
  }
  canByPrivilege(PRIVILEGES.createRedirectGroup, __spreadValues({
    createdOnLastMonth: {
      $lt: userPlanUsages.linkGroups.limit.value
    }
  }, workspaceFilter));
  canByPrivilege(PRIVILEGES.createLinkInBio, __spreadValues({
    alreadyCreated: {
      $lt: userPlanUsages.linkInBio.limit.value
    }
  }, workspaceFilter));
  if (!!userPlanUsages.linkInBioCustomBranding.limit.value) {
    canByPrivilege(PRIVILEGES.linkInBioCustomBranding, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkInBioCustomBranding, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.shareWithQRCode.limit.value) {
    canByPrivilege(PRIVILEGES.shareWithQRCode, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.shareWithQRCode, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.shareWithCustomDomain.limit.value) {
    canByPrivilege(PRIVILEGES.shareWithCustomDomain, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.shareWithCustomDomain, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.linkAnalytics.limit.value) {
    canByPrivilege(PRIVILEGES.linkAnalytics, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkAnalytics, __spreadValues({}, workspaceFilter));
  }
  canByPrivilege(PRIVILEGES.linkAnalyticsCustomRange, __spreadValues({
    distanceInHours: {
      $lt: userPlanUsages.linkAnalyticsCustomRange.limit.value
    }
  }, workspaceFilter));
  if (!!userPlanUsages.linkAnalyticsGeolocationMetrics.limit.value) {
    canByPrivilege(PRIVILEGES.linkAnalyticsGeolocationMetrics, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkAnalyticsGeolocationMetrics, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.linkAnalyticsOSMetrics.limit.value) {
    canByPrivilege(PRIVILEGES.linkAnalyticsOSMetrics, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkAnalyticsOSMetrics, __spreadValues({}, workspaceFilter));
  }
  if (!!userPlanUsages.linkAnalyticsUTMMetrics.limit.value) {
    canByPrivilege(PRIVILEGES.linkAnalyticsUTMMetrics, __spreadValues({}, workspaceFilter));
  } else {
    cannotByPrivilege(PRIVILEGES.linkAnalyticsUTMMetrics, __spreadValues({}, workspaceFilter));
  }
  return builder.rules;
};

// src/casl/authorizationService.ts
var CaslAuthorizationService = class {
  defineAbilitiesForUser({
    user,
    workspacesWithAccess,
    workspace,
    userPlanUsages
  }) {
    const { can: allow, build, rules } = getAbilityBuilder();
    if (!user) {
      return build();
    }
    const isAdmin = user.role === "admin";
    if (isAdmin) {
      allow("manage", "all");
      return build();
    }
    const subscriptionRules = getAuthRules({
      user,
      workspacesWithAccess,
      workspace,
      userPlanUsages
    });
    rules.push(...subscriptionRules);
    return build();
  }
  isAuthorized(param) {
    if (!this.ability) {
      console.error(
        `[AuthorizationService] - Service not configured. You need call the 'setup' or 'load' function before use it.`
      );
      return false;
    }
    if (param.invert) {
      return this.ability.cannot(
        param.action.action,
        (0, import_ability3.subject)(param.action.type, param.against),
        param.field
      );
    }
    return this.ability.can(
      param.action.action,
      (0, import_ability3.subject)(param.action.type, param.against),
      param.field
    );
  }
  setup(param) {
    const ability = this.defineAbilitiesForUser(param);
    this.ability = ability;
    return this;
  }
  saveToString() {
    var _a;
    return JSON.stringify(((_a = this.ability) == null ? void 0 : _a.rules) || []);
  }
  saveToObject() {
    var _a;
    return ((_a = this.ability) == null ? void 0 : _a.rules) || [];
  }
  load(state) {
    const rules = typeof state === "object" ? state : JSON.parse(state);
    if (Array.isArray(rules)) {
      const builder = getAbilityBuilder();
      builder.rules = rules;
      this.ability = builder.build();
    } else {
      throw new Error("Could not recognize the permissions");
    }
    return this;
  }
  isLoaded() {
    return !!this.ability;
  }
};

// src/authorizationService.ts
var AuthorizationServiceFactory = class {
  static create({ type }) {
    if (type === "casl") {
      return new CaslAuthorizationService();
    }
    throw new Error("Invalid type");
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AuthorizationServiceFactory,
  PRIVILEGES
});
