"use client";

import { languageOptions } from "i18n-config";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useIntercom } from "react-use-intercom";

export const useLanguageSwitch = () => {
  const [, setCookie] = useCookies();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const { hardShutdown } = useIntercom();

  const changeLanguage = useCallback(
    (language: string) => {
      const segments = pathName!.split("/");
      segments[1] = language;

      const newPathname = segments.join("/");

      console.debug(
        `[LANGUAGE] Redirect user from ${pathName} to ${newPathname}`
      );

      /**
       * This is a workaround to avoid this bug
       *
       * @see https://github.com/devrnt/react-use-intercom/issues/649#issuecomment-2095941164
       */
      hardShutdown();

      setCookie("NEXT_LOCALE", language);

      window.location.href = `${newPathname}?${new URLSearchParams(
        searchParams as any
      ).toString()}`;
    },
    [hardShutdown, pathName, searchParams, setCookie]
  );

  return {
    languageOptions,
    changeLanguage,
  };
};
