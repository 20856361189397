import { LoggedUser, useAuthStore, AuthState } from "../state";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSession } from "next-auth/react";
import { Session } from "next-auth";
import * as Sentry from "@sentry/nextjs";
import {
  AuthorizationServiceFactory,
  IAuthorizationService,
} from "@irbano/redilify-auth";
import { useCookies } from "react-cookie";
import { getURL } from "utils/vercel";
import { useIntercom } from "react-use-intercom";
import { usePostHog } from "posthog-js/react";

export type AuthContextProps = {
  children: React.ReactNode;
};

export type NextAuthContextContextData = {
  authorizationService: IAuthorizationService;
  isLoading: boolean;
  isRevalidating: boolean;
  isLoaded: boolean;
  authStore: AuthState;
  refreshUser: (data?: any) => Promise<void>;
};

const NextAuthContextContext = createContext<NextAuthContextContextData>(
  {} as NextAuthContextContextData
);

const NextAuthContext = ({ children }: AuthContextProps) => {
  const [, setCookie] = useCookies();
  const { update } = useIntercom();
  const posthog = usePostHog();
  const [authorizationService, setAuthorizationService] = useState<
    NextAuthContextContextData["authorizationService"]
  >(
    AuthorizationServiceFactory.create({
      type: "casl",
    })
  );

  const [isRevalidating, setIsRevalidating] = useState(false);

  const { data: session, update: updateSession } = useSession();
  const authStore = useAuthStore();

  const updateIntercomCookies = useCallback(
    (user: LoggedUser | null) => {
      const site = getURL();
      if (!user) {
        setCookie("@redilify-logged-user-email", undefined);
        setCookie("@redilify-logged-user-emailVerified", undefined);
        setCookie("@redilify-logged-user-planName", undefined);
        setCookie("@redilify-logged-user-id", undefined);
        setCookie("@redilify-logged-user-role", undefined);
        setCookie("@redilify-logged-user-stripeCustomerId", undefined);
        setCookie("@redilify-logged-user-createdAt", undefined);
        setCookie("@redilify-logged-user-site", site);
        setCookie("@redilify-logged-user-language", undefined);

        if (window && (window as any).intercomSettings) {
          (window as any).intercomSettings = {
            ...(window as any).intercomSettings,
            user_id: undefined,
            email: undefined,
            createdAt: undefined,
            userId: undefined,
            email_verified: undefined,
            has_valid_plan: undefined,
            stripe_customer_id: undefined,
            role: undefined,
            profile_id: undefined,
            profile_created_at: undefined,
            site,
            language: undefined,
          };
        }

        update();
        return;
      }

      const {
        email,
        emailVerified,
        plan,
        id,
        role,
        stripeCustomerId,
        language,
        createdAt,
      } = user;

      const createdAtTimestamp = Math.floor(
        new Date(createdAt!).getTime() / 1000
      );

      setCookie("@redilify-logged-user-email", email);
      setCookie("@redilify-logged-user-emailVerified", emailVerified);
      setCookie("@redilify-logged-user-planName", plan.name);
      setCookie("@redilify-logged-user-id", id);
      setCookie("@redilify-logged-user-role", role);
      setCookie("@redilify-logged-user-stripeCustomerId", stripeCustomerId);
      setCookie("@redilify-logged-user-createdAt", createdAtTimestamp);
      setCookie("@redilify-logged-user-site", site);
      setCookie("@redilify-logged-user-language", language);

      if (window && (window as any).intercomSettings) {
        (window as any).intercomSettings = {
          ...(window as any).intercomSettings,
          user_id: id,
          email,
          createdAt: createdAtTimestamp,
          userId: id,
          email_verified: emailVerified,
          planName: plan.name,
          stripe_customer_id: stripeCustomerId,
          role,
          site,
          language,
        };
      }

      update();
    },
    [setCookie, update]
  );

  const updateUserState = useCallback(
    async (userSession: Session | null) => {
      try {
        console.debug(`Updating user state`, userSession);
        setIsRevalidating(true);
        if (!userSession?.user) {
          Sentry.setUser(null);
          authStore.setUser(null);
          authStore.setTargetUser(undefined);
          authStore.setTargetWorkspace(undefined);

          updateIntercomCookies(null);

          setAuthorizationService(
            AuthorizationServiceFactory.create({
              type: "casl",
            })
          );

          return;
        }

        const jwtToken = "";
        const email = userSession.user.email as string;
        const id = userSession.user.id;
        const emailVerified = true;
        const role = userSession.user.role;
        const workspace = userSession.workspace;
        const authorizationState = userSession.authorizationState;

        const newAuthorizationService = AuthorizationServiceFactory.create({
          type: "casl",
        });

        newAuthorizationService.load(authorizationState);

        const newUser = {
          ...userSession.user,
          id,
          email,
          emailVerified,
          jwtToken,
          role,
          name: userSession.user.name || null,
          image: userSession.user.image || null,
          createdAt: userSession.user.createdAt,
        };
        Sentry.setUser({ id, email });
        posthog.identify(id, {
          email,
          role,
          name: newUser.name,
          createdAt: newUser.createdAt,
          language: userSession.user.language,
          cellphone: userSession.user.cellphone,
          currency: userSession.user.currency,
          stripeCustomerId: userSession.user.stripeCustomerId,
          indicatedBy: userSession.user.indicatedBy,
        });
        authStore.setUser(newUser);
        authStore.setTargetWorkspace(workspace);
        updateIntercomCookies(newUser);

        setAuthorizationService(newAuthorizationService);
      } catch (err) {
        console.error("Error during updateUserState", err);
      } finally {
        setIsRevalidating(false);
      }
    },
    [authStore, updateIntercomCookies]
  );

  useEffect(() => {
    updateUserState(session);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (isRevalidating) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.targetWorkspace, isRevalidating]);

  const isLoading = useMemo(
    () => !authorizationService,
    [authorizationService]
  );

  const refreshUser = async (data?: any) => {
    const newSession = await updateSession(data);

    await updateUserState(newSession);
  };

  return (
    <NextAuthContextContext.Provider
      value={{
        authorizationService,
        isLoading,
        isRevalidating,
        isLoaded: authorizationService.isLoaded(),
        authStore,
        refreshUser,
      }}
    >
      {children}
    </NextAuthContextContext.Provider>
  );
};

export default NextAuthContext;

export function useAuthContext() {
  const context = useContext(NextAuthContextContext);

  if (!context) {
    throw new Error("useAuthContext must be used within a AuthContext");
  }

  return context;
}
