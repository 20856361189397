"use client";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { Limit } from "@irbano/redilify-domain";
import clsx from "clsx";
import { PricingSectionTranslations } from "./types";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { useMemo } from "react";

export type FeatureLimitComponentProps = {
  limit: Limit;
  featured?: boolean;
  featureName: string;
  translation: TranslationProp<PricingSectionTranslations>;
};

export const FeatureLimitComponent = ({
  limit,
  featured,
  translation,
  featureName,
}: FeatureLimitComponentProps) => {
  const { t } = useTranslation({ translation });
  const formattedValue = useMemo(() => {
    if (limit.type === "monthly") {
      return `${limit.value} ${t("pricingSection.byMonth")}`;
    } else if (limit.type === "total") {
      if (
        ["linkClicksRetention", "linkAnalyticsCustomRange"].includes(
          featureName
        )
      ) {
        const valuedays = Math.floor(limit.value / 24);
        return `${valuedays} ${
          valuedays === 1 ? t("pricingSection.day") : t("pricingSection.days")
        }`;
      }
    }

    return limit.value;
  }, [limit.type, limit.value, t, featureName]);

  if (limit.type === "boolean") {
    return (
      <>
        {!!limit.value === true ? (
          <CheckIcon
            className="mx-auto h-5 w-5 text-primary-600"
            aria-hidden="true"
          />
        ) : (
          <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
        )}

        <span className="sr-only">{!!limit.value === true ? "Yes" : "No"}</span>
      </>
    );
  }

  return (
    <span
      className={clsx(
        featured ? "font-semibold text-primary-600" : "text-gray-900",
        "text-sm leading-6"
      )}
    >
      {formattedValue}
    </span>
  );
};
