"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Click: () => Click,
  ClickType: () => ClickType,
  ClicksSum: () => ClicksSum,
  Destination: () => Destination,
  DestinationClicksByDay: () => DestinationClicksByDay,
  DestinationClicksByHour: () => DestinationClicksByHour,
  DestinationClicksByMonth: () => DestinationClicksByMonth,
  FEATURE_NAMES: () => FEATURE_NAMES,
  NotFoundError: () => NotFoundError,
  PLANS: () => PLANS,
  PLANS_MAP: () => PLANS_MAP,
  Redirect: () => Redirect,
  RedirectGroup: () => RedirectGroup,
  ShortUrl: () => ShortUrl,
  Subscription: () => Subscription,
  UserProfile: () => UserProfile,
  businessPlan: () => businessPlan,
  freePlan: () => freePlan,
  getHighestLimit: () => getHighestLimit,
  premiumPlan: () => premiumPlan,
  proPlan: () => proPlan
});
module.exports = __toCommonJS(src_exports);

// src/entities/Click.ts
var ClickType = /* @__PURE__ */ ((ClickType2) => {
  ClickType2["simple"] = "simple";
  ClickType2["unique"] = "unique";
  return ClickType2;
})(ClickType || {});
var Click = class {
};

// src/entities/Destination.ts
var Destination = class {
};

// src/entities/Redirect.ts
var Redirect = class {
};

// src/entities/RedirectGroup.ts
var RedirectGroup = class {
};

// src/entities/ShortUrl.ts
var ShortUrl = class {
};

// src/entities/Subscription.ts
var Subscription = class {
};

// src/entities/UserProfile.ts
var UserProfile = class {
};

// src/entities/metrics/ClicksSum.ts
var ClicksSum = class {
};

// src/entities/metrics/DestinationClicksByHour.ts
var DestinationClicksByHour = class extends ClicksSum {
};

// src/entities/metrics/DestinationClicksByDay.ts
var DestinationClicksByDay = class extends ClicksSum {
};

// src/entities/metrics/DestinationClicksByMonth.ts
var DestinationClicksByMonth = class extends ClicksSum {
};

// src/plans/premium.ts
var linksPerMonth = 150;
var premiumPlan = {
  name: "premium",
  stripeProductIds: [""],
  features: {
    links: {
      limit: {
        type: "monthly",
        value: linksPerMonth
      }
    },
    editLinkDestinations: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    destinationsPerLink: {
      limit: {
        type: "total",
        value: 3
      }
    },
    customDomains: {
      limit: {
        type: "total",
        value: 1
      }
    },
    linkGroups: {
      limit: {
        type: "monthly",
        value: 30
      }
    },
    tags: {
      limit: {
        type: "total",
        value: 30
      }
    },
    workspaces: {
      limit: {
        type: "total",
        value: 1
      }
    },
    usersPerWorkspace: {
      limit: {
        type: "total",
        value: 1
      }
    },
    customShortId: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    deepLink: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    queryParamsProxy: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    utmBuilder: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkExpiration: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    openGraph: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    facebookPixel: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    gtm: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkInBio: {
      limit: {
        type: "total",
        value: 3
      }
    },
    linkInBioCustomBranding: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    shareWithQRCode: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithCustomDomain: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalytics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkClicksRetention: {
      limit: {
        type: "total",
        value: 120
      }
    },
    linkAnalyticsCustomRange: {
      limit: {
        type: "total",
        value: 720
      }
    },
    linkAnalyticsGeolocationMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsOSMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsUTMMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    }
  }
};

// src/plans/pro.ts
var linksPerMonth2 = 600;
var proPlan = {
  name: "pro",
  stripeProductIds: [""],
  features: {
    links: {
      limit: {
        type: "monthly",
        value: linksPerMonth2
      }
    },
    editLinkDestinations: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    destinationsPerLink: {
      limit: {
        type: "total",
        value: 5
      }
    },
    customDomains: {
      limit: {
        type: "total",
        value: 5
      }
    },
    linkGroups: {
      limit: {
        type: "monthly",
        value: 120
      }
    },
    tags: {
      limit: {
        type: "total",
        value: 50
      }
    },
    workspaces: {
      limit: {
        type: "total",
        value: 3
      }
    },
    usersPerWorkspace: {
      limit: {
        type: "total",
        value: 3
      }
    },
    customShortId: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    deepLink: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    queryParamsProxy: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    utmBuilder: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkExpiration: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    openGraph: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    facebookPixel: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    gtm: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkInBio: {
      limit: {
        type: "total",
        value: 5
      }
    },
    linkInBioCustomBranding: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithQRCode: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithCustomDomain: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalytics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkClicksRetention: {
      limit: {
        type: "total",
        value: 365
      }
    },
    linkAnalyticsCustomRange: {
      limit: {
        type: "total",
        value: 168
      }
    },
    linkAnalyticsGeolocationMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsOSMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsUTMMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    }
  }
};

// src/plans/business.ts
var linksPerMonth3 = 5e3;
var businessPlan = {
  name: "business",
  stripeProductIds: [""],
  features: {
    links: {
      limit: {
        type: "monthly",
        value: linksPerMonth3
      }
    },
    editLinkDestinations: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    destinationsPerLink: {
      limit: {
        type: "total",
        value: 10
      }
    },
    customDomains: {
      limit: {
        type: "total",
        value: 10
      }
    },
    linkGroups: {
      limit: {
        type: "monthly",
        value: 1e3
      }
    },
    tags: {
      limit: {
        type: "total",
        value: 100
      }
    },
    workspaces: {
      limit: {
        type: "total",
        value: 10
      }
    },
    usersPerWorkspace: {
      limit: {
        type: "total",
        value: 15
      }
    },
    customShortId: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    deepLink: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    queryParamsProxy: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    utmBuilder: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkExpiration: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    openGraph: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    facebookPixel: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    gtm: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkInBio: {
      limit: {
        type: "total",
        value: 20
      }
    },
    linkInBioCustomBranding: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithQRCode: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithCustomDomain: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalytics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkClicksRetention: {
      limit: {
        type: "total",
        value: 730
      }
    },
    linkAnalyticsCustomRange: {
      limit: {
        type: "total",
        value: 90 * 24
        //DAYS * 24 HOURS
      }
    },
    linkAnalyticsGeolocationMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsOSMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkAnalyticsUTMMetrics: {
      limit: {
        type: "boolean",
        value: 1
      }
    }
  }
};

// src/plans/free.ts
var linksPerMonth4 = 15;
var freePlan = {
  name: "free",
  stripeProductIds: [],
  features: {
    links: {
      limit: {
        type: "monthly",
        value: linksPerMonth4
      }
    },
    editLinkDestinations: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    destinationsPerLink: {
      limit: {
        type: "total",
        value: 2
      }
    },
    customDomains: {
      limit: {
        type: "total",
        value: 0
      }
    },
    linkGroups: {
      limit: {
        type: "monthly",
        value: 3
      }
    },
    tags: {
      limit: {
        type: "total",
        value: 10
      }
    },
    workspaces: {
      limit: {
        type: "total",
        value: 0
      }
    },
    usersPerWorkspace: {
      limit: {
        type: "total",
        value: 0
      }
    },
    customShortId: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    deepLink: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    queryParamsProxy: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    utmBuilder: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    linkExpiration: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    openGraph: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    facebookPixel: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    gtm: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkInBio: {
      limit: {
        type: "total",
        value: 1
      }
    },
    linkInBioCustomBranding: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    shareWithQRCode: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    shareWithCustomDomain: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    linkAnalytics: {
      limit: {
        type: "boolean",
        value: 1
      }
    },
    linkClicksRetention: {
      limit: {
        type: "total",
        value: 30
      }
    },
    linkAnalyticsCustomRange: {
      limit: {
        type: "total",
        value: 24
      }
    },
    linkAnalyticsGeolocationMetrics: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    linkAnalyticsOSMetrics: {
      limit: {
        type: "boolean",
        value: 0
      }
    },
    linkAnalyticsUTMMetrics: {
      limit: {
        type: "boolean",
        value: 0
      }
    }
  }
};

// src/plans/utils.ts
var getHighestLimit = (limitA, limitB) => {
  if (limitA.type === "unlimited" || limitA.type === "boolean" && !!limitA.value)
    return limitA;
  if (limitB.type === "unlimited" || limitB.type === "boolean" && !!limitB.value)
    return limitB;
  if (limitA.type === "boolean" && limitB.type === "boolean") {
    return limitA.value === 1 ? limitA : limitB;
  }
  if (limitA.type === "total" && limitB.type === "total") {
    return limitA.value >= limitB.value ? limitA : limitB;
  }
  if (limitA.type === "monthly" && limitB.type === "monthly") {
    return limitA.value >= limitB.value ? limitA : limitB;
  }
  if (limitA.type === "total" && limitB.type === "monthly")
    return limitB;
  if (limitA.type === "monthly" && limitB.type === "total")
    return limitA;
  if (limitA.type === "boolean")
    return limitB;
  if (limitB.type === "boolean")
    return limitA;
  if (limitA.type === "monthly")
    return limitA;
  if (limitB.type === "monthly")
    return limitA;
  console.error("It was not possible to set the best limit for the user", {
    limitA,
    limitB
  });
  return limitA;
};

// src/plans/index.ts
var FEATURE_NAMES = Object.keys(freePlan.features);
var PLANS = [freePlan, premiumPlan, proPlan, businessPlan];
var PLANS_MAP = {
  free: freePlan,
  premium: premiumPlan,
  pro: proPlan,
  business: businessPlan
};

// src/errors/index.ts
var NotFoundError = class extends Error {
  constructor({ message, code } = {}) {
    super(message);
    this.code = code;
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Click,
  ClickType,
  ClicksSum,
  Destination,
  DestinationClicksByDay,
  DestinationClicksByHour,
  DestinationClicksByMonth,
  FEATURE_NAMES,
  NotFoundError,
  PLANS,
  PLANS_MAP,
  Redirect,
  RedirectGroup,
  ShortUrl,
  Subscription,
  UserProfile,
  businessPlan,
  freePlan,
  getHighestLimit,
  premiumPlan,
  proPlan
});
