"use client";
import React, { useEffect, useMemo, useState } from "react";
import { PlansCTAButtonProps, PricingSectionTranslations } from "../types";
import clsx from "clsx";
import type { Price } from "server/utils/stripe";
import { usePlansContext } from "../PlansContext";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@irbano/react-components/dist/components/ui/popover";

import { Button } from "@irbano/react-components/dist/components/ui/button";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { TRIAL_PERIOD_DAYS } from "shared/constants";
import { UserPlanResponse } from "server/routers/user";
import { trpc } from "utils/trpc";
import { getURL } from "utils/vercel";
import { useRouter } from "next/router";
import { useCheckoutUrl } from "../useCheckoutUrl";
import { usePostHog } from "posthog-js/react";

export type LoggedInChoosePlanButtonProps = PlansCTAButtonProps & {
  onClick: (price: Price) => void;
  disabled?: boolean;
  alreadyActive?: boolean;
  isLoading?: boolean;
  defaultStripePriceId?: string;
  translation: TranslationProp<PricingSectionTranslations>;
  plan: UserPlanResponse;
};
export const LoggedInChoosePlanButton = ({
  tier,
  onClick,
  disabled,
  alreadyActive,
  isLoading,
  defaultStripePriceId,
  translation,
  plan,
}: LoggedInChoosePlanButtonProps) => {
  const posthog = usePostHog();
  const { t } = useTranslation({ translation } as any);
  const [isOpen, setIsOpen] = useState(false);
  const { currency, frequency } = usePlansContext();
  const router = useRouter();
  const createUpdatePlanCheckoutUrlMutation =
    trpc.stripe.createUpdatePlanCheckoutUrl.useMutation();

  const price = useMemo(
    () => tier.prices?.[frequency.value]?.[currency.value],
    [currency, frequency.value, tier.prices]
  );

  const { checkoutUrl } = useCheckoutUrl({ price, plan: tier.name });

  useEffect(() => {
    if (
      defaultStripePriceId &&
      price &&
      defaultStripePriceId === price.stripePriceId &&
      !alreadyActive
    ) {
      setIsOpen(true);
    }
  }, [alreadyActive, defaultStripePriceId, price]);

  const type = useMemo(() => {
    if (plan.plan.name === tier.name) {
      return "manage";
    }

    return "change";
  }, [plan.plan.name, tier.name]);

  if (alreadyActive) {
    return null;
  }

  if (type === "change") {
    return (
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger disabled={disabled} className="w-full">
          <Button
            disabled={disabled}
            aria-describedby={`Tier_${tier.name}`}
            isLoading={isLoading}
            className={clsx(
              tier.featured
                ? "bg-primary-600 shadow-sm hover:bg-primary-500 focus-visible:outline-primary-600"
                : "bg-white/10 hover:bg-white/20 focus-visible:outline-white",
              "rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full"
            )}
          >
            {t("pricingSection.buttonText.title")}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col max-w-full p-4 md:p-6">
          <div className="flex-grow">
            <div>
              <span className="text-sm font-semibold">
                {t("pricingSection.confirmPlanPopover.title")}
              </span>
            </div>
            <div className="mt-1 text-sm">
              <span>{t("pricingSection.confirmPlanPopover.description")}</span>
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <Button
              type="button"
              onClick={async () => {
                if (plan.subscription) {
                  const checkout =
                    await createUpdatePlanCheckoutUrlMutation.mutateAsync({
                      domain: getURL(),
                      newPriceId:
                        tier.prices?.[frequency.value]?.[currency.value]
                          ?.stripePriceId,
                    });

                  router.push(checkout.checkoutUrl);

                  return;
                }

                router.push(checkoutUrl);
              }}
              disabled={
                disabled || createUpdatePlanCheckoutUrlMutation.isPending
              }
              className="w-full sm:w-auto"
            >
              {t("pricingSection.confirmPlanPopover.confirmButton")}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger disabled={disabled} className="w-full">
        <Button
          disabled={disabled}
          aria-describedby={`Tier_${tier.name}`}
          isLoading={isLoading}
          className={clsx(
            tier.featured
              ? "bg-primary-600 shadow-sm hover:bg-primary-500 focus-visible:outline-primary-600"
              : "bg-white/10 hover:bg-white/20 focus-visible:outline-white",
            "rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full"
          )}
          onClick={() => {
            posthog.capture("logged_pricing_section_cta_clicked", {
              plan: tier.name,
              currency: currency.value,
              frequency: frequency.value,
            });
          }}
        >
          {alreadyActive
            ? t("pricingSection.cta.planAlreadyActive")
            : price?.trialDays
            ? t("pricingSection.cta.startFreeTrial", {
                days: TRIAL_PERIOD_DAYS,
              })
            : t("pricingSection.cta.choosePlan")}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div>
          <span className="text-sm font-semibold">
            {t("pricingSection.confirmPlanPopover.title")}
          </span>
        </div>
        <div className="mt-1 text-sm">
          <span>{t("pricingSection.confirmPlanPopover.description")}</span>
        </div>

        <div className="flex justify-end items-center mt-2">
          <Button
            onClick={() => {
              posthog.capture("logged_pricing_section_cta_dialog_confirm", {
                plan: tier.name,
                currency: currency.value,
                frequency: frequency.value,
              });

              onClick(tier.prices?.[frequency.value]?.[currency.value]);
            }}
            disabled={disabled}
          >
            {t("pricingSection.confirmPlanPopover.confirmButton")}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
