import { Button } from "@irbano/react-components/dist/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@irbano/react-components/dist/components/ui/dialog";
import { useToast } from "@irbano/react-components/dist/components/ui/toast";
import { useTranslation } from "next-i18next";
import { trpc } from "utils/trpc";

export type RenewPlanModalProps = {
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  afterRenewPlan(): void;
};
export const RenewPlanModal = ({
  isOpen,
  onOpenChange,
  afterRenewPlan,
}: RenewPlanModalProps) => {
  const { t } = useTranslation(["common"]);
  const { toast } = useToast();
  const abortPlanCancellationMutation =
    trpc.stripe.abortPlanCancellation.useMutation();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex w-full p-4">
            {t("CurrentPlan.renewModal.title")}
          </DialogTitle>
          <DialogFooter className="justify-end">
            <Button
              type="button"
              variant="secondary"
              onClick={() => onOpenChange(false)}
              className="mr-4"
            >
              {t("CurrentPlan.renewModal.cancelButton")}
            </Button>
            <Button
              type="button"
              variant="primary"
              disabled={abortPlanCancellationMutation.isPending}
              onClick={async () => {
                await abortPlanCancellationMutation.mutateAsync();

                toast({
                  variant: "success",
                  title: t("CurrentPlan.renewModal.successPopup.title"),
                  description: t(
                    "CurrentPlan.renewModal.successPopup.description"
                  ),
                });

                onOpenChange(false);
                afterRenewPlan();
              }}
            >
              {t("CurrentPlan.renewModal.renewButton")}
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
