"use client";
import Image from "next/image";
import { useMemo } from "react";
import { Locale } from "i18n-config";

import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";

import {
  getIntercomArticleUrl,
  getIntercomHelpCenterUrl,
} from "utils/intercom";
import Link from "next/link";
import { useLanguageSwitch } from "app/[lang]/hooks/useChangeLanguage";
import { Button } from "@irbano/react-components/dist/components/ui/button";

export type FooterTranslations = {
  footer: {
    title: string;
    subTitle: string;
    policy: string;
    terms: string;
    copyrightNotice: string;
    aboutUs: string;
    ourMissionAndValues: string;
    customDomain: string;
    solutions: string;
    support: string;
    company: string;
    legal: string;
    helpCenter: string;
  };
};
export type FooterProps = {
  translations: FooterTranslations;
  lang: Locale;
};

export default function Footer({ translations, lang }: FooterProps) {
  const { languageOptions, changeLanguage } = useLanguageSwitch();

  const navigation = useMemo(() => {
    return {
      social: [
        {
          name: "Instagram",
          href: "https://www.instagram.com/redilify",
          Icon: FaInstagram,
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "Facebook",
          href: "https://www.facebook.com/profile.php?id=61556946200681",
          Icon: FaFacebook,
          target: "_blank",
          rel: "noopener noreferrer",
        },
        {
          name: "Youtube",
          href: "https://www.youtube.com/@Redilify",
          Icon: FaYoutube,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      ],
      solutions: [
        { name: "Deep Link", href: getIntercomArticleUrl("deepLink", lang) },
        {
          name: "Social Media Preview",
          href: getIntercomArticleUrl("socialMediaPreview", lang),
        },
        {
          name: "Google Tag Manager & Facebook Pixel",
          href: getIntercomArticleUrl("tagManagerAndPixel", lang),
        },
        {
          name: translations.footer.customDomain,
          href: getIntercomArticleUrl("customDomain", lang),
        },
      ],
      support: [
        {
          name: translations.footer.helpCenter,
          href: getIntercomHelpCenterUrl(lang),
          target: "_blank",
          rel: "noopener noreferrer",
        },
        { name: "E-mail", href: "mailto:contact@redilify.com" },
      ],
      company: [
        {
          name: translations.footer.aboutUs,
          href: getIntercomArticleUrl("aboutUs", lang),
        },
        {
          name: translations.footer.ourMissionAndValues,
          href: getIntercomArticleUrl("ourMissionAndValues", lang),
        },
      ],
      legal: [
        { name: translations.footer.policy, href: "/policy" },
        { name: translations.footer.terms, href: "/terms" },
      ],
    };
  }, [
    lang,
    translations.footer.aboutUs,
    translations.footer.customDomain,
    translations.footer.helpCenter,
    translations.footer.ourMissionAndValues,
    translations.footer.policy,
    translations.footer.terms,
  ]);

  return (
    <footer
      className="w-full bg-gradient-to-b from-white to-[#f1f6f9] "
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 py-8 lg:py-12">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <div className="flex flex-col">
              <div className="w-[100px] h-[56.25px] lg:w-[140px] lg:h-[78.75px] relative">
                <Image
                  src="/logos/only-name.png"
                  alt="Redilify"
                  fill
                  objectFit="contain"
                  className="absolute"
                  priority={true}
                />
              </div>
              <p className="text-sm text-secondary">
                {translations.footer.subTitle}
              </p>
            </div>

            <div className="flex gap-1 mt-2">
              {navigation.social.map(({ name, Icon, ...linkProps }) => (
                <Link
                  key={`Navigation_social_${name}`}
                  {...linkProps}
                  className="text-gray-500 hover:text-gray-400 space-x-1"
                >
                  <span className="sr-only">{name}</span>
                  <Icon
                    className="h-[1.4rem] w-[1.4rem] text-secondary-700 hover:text-primary transition-all delay-75"
                    aria-hidden="true"
                  />
                </Link>
              ))}
            </div>
          </div>

          <div className="mt-8 lg:mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-secondary">
                  {translations.footer.solutions}
                </h3>
                <ul role="list" className="mt-6 space-y-1">
                  {navigation.solutions.map((item) => (
                    <li key={`Navigation_solution_${item.name}`}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-secondary "
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-secondary">
                  {translations.footer.support}
                </h3>
                <ul role="list" className="mt-6 space-y-1">
                  {navigation.support.map(({ name, ...linkProps }) => (
                    <li key={`Navigation_support_${name}`}>
                      <Link
                        {...linkProps}
                        className="text-sm leading-6 text-secondary"
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-secondary">
                  {translations.footer.company}
                </h3>
                <ul role="list" className="mt-6 space-y-1">
                  {navigation.company.map(({ name, href }) => (
                    <li key={`Navigation_company_${name}`}>
                      <Link
                        href={href}
                        className="text-sm leading-6 text-secondary "
                      >
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-secondary">
                  {translations.footer.legal}
                </h3>
                <ul role="list" className="mt-6 space-y-1">
                  {navigation.legal.map((item) => (
                    <li key={`Navigation_legal_${item.name}`}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-secondary "
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-px bg-gray-300 mt-4"></div>
        <div className="flex mt-4 gap-4 text-sm">
          {languageOptions.map((language) => (
            <Button
              variant="link"
              key={`Language_option_${language.value}`}
              onClick={() => changeLanguage(language.value)}
            >
              {language.label}
            </Button>
          ))}
        </div>
        <div className="mt-4 border-t border-white/10 pt-4">
          <p className="text-xs leading-5 text-gray-400">
            &copy; {translations.footer.copyrightNotice}
          </p>
        </div>
      </div>
    </footer>
  );
}
