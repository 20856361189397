import { Progress } from "@irbano/react-components/dist/components/ui/progress";
import { FeatureLimitType } from "@irbano/redilify-prisma";
import { useTranslation } from "next-i18next";
import React from "react";
import { ConsolidatedFeatureUsage } from "server/routers/user";

export type FeatureUsageProgressProps = {
  featureUsage: ConsolidatedFeatureUsage;
};

export const FeatureUsageProgress = ({
  featureUsage,
}: FeatureUsageProgressProps) => {
  const { t } = useTranslation(["common", "lp"]);
  const { limit, limitType } = featureUsage.planFeature;

  if (limitType === FeatureLimitType.boolean) {
    return null;
  }

  const used = limit ? (featureUsage.total / limit) * 100 : 0;

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-sm text-zinc-700">
          {t(`lp:features.${featureUsage.planFeature.feature.name}.title`)}
        </p>
        <p className="text-end">
          {used.toFixed(1)}% ({featureUsage.total}/{limit})
          {limitType === FeatureLimitType.monthly
            ? t("CurrentPlan.byMonth")
            : null}
        </p>
      </div>
      <Progress value={used} className="h-2 mb-3" />
    </div>
  );
};
