const getUrlFromBrowser = () => window.location.origin;
const getUrlFromServer = () => {
  // Set this to your site URL in production env.
  if (process?.env?.NEXT_PUBLIC_SITE_URL) {
    return process.env.NEXT_PUBLIC_SITE_URL;
  }
  // Automatically set by Vercel.
  return process?.env?.NEXT_PUBLIC_VERCEL_URL;
};

/**
 * Returns actual hostname
 * @returns Url like http://localhost:3000/
 */
export const getURL = () => {
  const isServer = typeof window === "undefined";
  let url =
    (isServer ? getUrlFromServer() : getUrlFromBrowser()) ||
    "http://localhost:3000/";

  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;

  return url;
};
