"use client";
import React, { useMemo } from "react";
import { PlansCTAButtonProps } from "./types";
import clsx from "clsx";
import { usePlansContext } from "./PlansContext";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@irbano/react-components/dist/components/ui/tabs";
import { Button } from "@irbano/react-components/dist/components/ui/button";
import {
  SignUpForm,
  SignUpFormTranslations,
} from "app/[lang]/auth/signup/signup-form";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@irbano/react-components/dist/components/ui/card";
import {
  SignInForm,
  SignInFormTranslations,
} from "app/[lang]/auth/signin/sign-in-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@irbano/react-components/dist/components/ui/dialog";
import { useCheckoutUrl } from "./useCheckoutUrl";
import { usePostHog } from "posthog-js/react";

export const LoggedOutChoosePlanButton = ({
  tier,
  translation,
}: PlansCTAButtonProps) => {
  const posthog = usePostHog();
  const { t } = useTranslation({ translation });
  const { currency, frequency } = usePlansContext();

  const price = useMemo(
    () => tier.prices?.[frequency.value]?.[currency.value],
    [currency, frequency.value, tier.prices]
  );

  const { checkoutUrl } = useCheckoutUrl({ price, plan: tier.name });

  return (
    <Dialog
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          posthog.capture("pricing_section_login_dialog_closed", {
            plan: tier.name,
            currency: currency.value,
            frequency: frequency.value,
          });
        }
      }}
    >
      <DialogTrigger asChild>
        <Button
          id={`pricing-section-${tier.name}-button`}
          aria-describedby={`Tier_${tier.name}`}
          className={clsx(
            tier.featured
              ? "bg-primary-600 shadow-sm hover:bg-primary-500 focus-visible:outline-primary-600"
              : "bg-white/10 hover:bg-white/20 focus-visible:outline-white",
            "rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          )}
          onClick={() => {
            posthog.capture("pricing_section_cta_clicked", {
              plan: tier.name,
              currency: currency.value,
              frequency: frequency.value,
            });
          }}
        >
          {price?.trialDays && price.value > 0
            ? t("pricingSection.cta.startFreeTrial", {
                days: price.trialDays,
              })
            : t("pricingSection.cta.choosePlan")}
          {}
        </Button>
      </DialogTrigger>
      <DialogContent
        // We dont want to allow closing the dialog by clicking outside
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader className="p-6">
          <Tabs defaultValue="sign-up">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="sign-up">
                {t("auth.signUp.title")}
              </TabsTrigger>
              <TabsTrigger value="sign-in">
                {t("auth.signIn.title")}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="sign-up">
              <Card>
                <CardHeader>
                  <CardTitle>{t("auth.signUp.title")}</CardTitle>
                  <CardDescription>
                    {t("auth.signUp.description")}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <SignUpForm
                    translation={
                      translation as TranslationProp<SignUpFormTranslations>
                    }
                    callbackUrl={checkoutUrl}
                  />
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="sign-in">
              <Card>
                <CardHeader>
                  <CardTitle>{t("auth.signIn.title")}</CardTitle>
                  <CardDescription>
                    {t("auth.signIn.description")}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <SignInForm
                    translation={
                      translation as TranslationProp<SignInFormTranslations>
                    }
                    callbackUrl={checkoutUrl}
                  />
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
