import { Locale } from "i18n-config";
import { Currency } from "@irbano/redilify-prisma";

export const localeToCurrency = (locale?: Locale): Currency => {
  if (locale === "pt-BR") {
    return "brl";
  }

  return "usd";
};
