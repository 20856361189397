import { Button } from "@irbano/react-components/dist/components/ui/button";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { UserPlanResponse } from "server/routers/user";
import { trpc } from "utils/trpc";
import { getURL } from "utils/vercel";
import { RenewPlanModal } from "./RenewPlanModal";
import { FeatureUsageProgress } from "./FeatureUsageProgress";
import { useAuthContext } from "shared/contexts/AuthContext";
import Link from "next/link";

export type CurrentPlanProps = {
  plan: UserPlanResponse;
  refetchPlan: () => Promise<void>;
};

export const CurrentPlan = ({ plan, refetchPlan }: CurrentPlanProps) => {
  const { t } = useTranslation(["common", "lp"]);
  const router = useRouter();
  const createCancelPlanCheckoutUrlMutation =
    trpc.stripe.createCancelPlanCheckoutUrl.useMutation();
  const getBillingPortal = trpc.stripe.getBillingPortal.useMutation();
  const { authStore } = useAuthContext();
  const [isRenewPlanModalOpen, setIsRenewPlanModalOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const endDate = useMemo(() => {
    if (!plan.subscription?.cancelAt && !plan.subscription?.trialEndAt) {
      return null;
    }

    if (plan.subscription?.cancelAt) {
      return plan.subscription.cancelAt.toLocaleDateString(
        authStore.user?.language ?? "en-US",
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }
      );
    }

    return plan.subscription.trialEndAt?.toLocaleDateString(
      authStore.user?.language ?? "en-US",
      {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }
    );
  }, [
    authStore.user?.language,
    plan?.subscription?.cancelAt,
    plan?.subscription?.trialEndAt,
  ]);

  return (
    <main className="relative w-full flex justify-center items-center mt-44 mb-64 md:mt-32 lg:mt-20 lg:mb-52">
      <div className="absolute w-full md:max-w-md lg:max-w-none lg:w-2/3 flex flex-col bg-white rounded-2xl z-50">
        <div className="shadow-md p-6">
          <div className="w-full md:flex md:justify-between md:items-center">
            <div>
              <p>{t("CurrentPlan.description")}</p>
              <h1 className="text-4xl text-secondary-900 font-semibold">
                {t(`lp:pricingSection.plans.${plan.plan.name}.label`)}
              </h1>
              {endDate ? (
                <p>
                  {t("CurrentPlan.endDate")} {endDate}
                </p>
              ) : null}
            </div>
            <div className="flex flex-row items-center justify-center gap-4 pt-4 md:flex-col lg:flex-row">
              <Button
                className="text-black rounded-xl bg-primary hover:bg-primary-400"
                type="button"
                size="lg"
                isLoading={isButtonLoading}
                onClick={async () => {
                  setIsButtonLoading(true);
                  const billingPortal = await getBillingPortal.mutateAsync({
                    userId: authStore.user!.id,
                    domain: getURL(),
                  });
                  setIsButtonLoading(false);
                  router.push(billingPortal.url);
                }}
              >
                {t("CurrentPlan.manageMySubscription")}
              </Button>
              {plan.subscription && plan.plan.name !== "free" && !endDate ? (
                <Button
                  type="button"
                  size="lg"
                  isLoading={createCancelPlanCheckoutUrlMutation.isPending}
                  onClick={async () => {
                    const checkout =
                      await createCancelPlanCheckoutUrlMutation.mutateAsync({
                        domain: getURL(),
                      });
                    router.push(checkout.checkoutUrl);
                  }}
                  className="rounded-xl bg-primary-800 hover:bg-primary-900"
                >
                  {t("CurrentPlan.button.cancel")}
                </Button>
              ) : null}
              {plan.subscription && endDate ? (
                <>
                  <Button
                    type="button"
                    size="lg"
                    className="rounded-xl text-white bg-primary-800 hover:bg-primary-900"
                    disabled={isRenewPlanModalOpen}
                    onClick={() => {
                      setIsRenewPlanModalOpen(true);
                    }}
                  >
                    {t("CurrentPlan.button.renew")}
                  </Button>
                  <RenewPlanModal
                    isOpen={isRenewPlanModalOpen}
                    onOpenChange={setIsRenewPlanModalOpen}
                    afterRenewPlan={() => {
                      refetchPlan();
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
        {plan.featureUsages.length > 0 ? (
          <div className="p-6">
            <div className="lg:flex">
              <div className="flex max-lg:flex-col w-full justify-between">
                <div className="flex flex-col">
                  <p className="font-semibold pb-2">{t("CurrentPlan.Label")}</p>
                  <Link
                    href="#"
                    onClick={async () => {
                      setIsButtonLoading(true);
                      const billingPortal = await getBillingPortal.mutateAsync({
                        userId: authStore.user!.id,
                        domain: getURL(),
                      });
                      setIsButtonLoading(false);
                      router.push(billingPortal.url);
                    }}
                    className="text-blue-400 hover:underline cursor-pointer"
                  >
                    {t("CurrentPlan.Links.Payments")}
                  </Link>
                  <Link
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      setIsButtonLoading(true);
                      const billingPortal = await getBillingPortal.mutateAsync({
                        userId: authStore.user!.id,
                        domain: getURL(),
                      });
                      setIsButtonLoading(false);
                      router.push(billingPortal.url);
                    }}
                    className="text-blue-400 hover:underline cursor-pointer"
                  >
                    {t("CurrentPlan.Links.Information")}
                  </Link>
                </div>
                <div className="flex flex-col lg:w-1/2 pt-6 lg:pt-0 lg:pb-4">
                  <p className="font-semibold pb-2">
                    {t("CurrentPlan.limits")}
                  </p>
                  {plan.featureUsages.map((featureUsage) => {
                    return (
                      <FeatureUsageProgress
                        key={`FeatureUsageProgress_${featureUsage.planFeature.id}`}
                        featureUsage={featureUsage}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
};
