import { useMemo } from "react";
import { usePlansContext } from "./PlansContext";
import { Price } from "server/utils/stripe";
import { PlanName } from "@irbano/redilify-domain";

export const useCheckoutUrl = ({
  price,
  plan,
}: {
  price: Price;
  plan: PlanName;
}) => {
  const { currency, frequency } = usePlansContext();

  const checkoutUrl = useMemo(() => {
    const callbackQueryParams = new URLSearchParams({
      stripePriceId: price?.stripePriceId,
      stripeCoupon: price?.discount?.coupon ?? "",
      defaultCurrency: currency.value,
      defaultFrequency: frequency.value,
      plan,
    }).toString();

    const parsedCallbackUrl = `/api/checkout?${callbackQueryParams}`;

    return parsedCallbackUrl;
  }, [
    currency.value,
    frequency.value,
    plan,
    price?.discount?.coupon,
    price?.stripePriceId,
  ]);

  return {
    checkoutUrl,
  };
};
