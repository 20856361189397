export const i18n = {
  defaultLocale: "pt-BR",
  locales: ["en-US", "pt-BR"],
} as const;

export const languageOptions = [
  {
    label: "Português",
    shortLabel: "PT-BR",
    value: "pt-BR" as Locale,
  },
  {
    label: "English",
    shortLabel: "EN-US",
    value: "en-US" as Locale,
  },
];

export type Locale = (typeof i18n)["locales"][number];

type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];

type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
    ? `${F}${D}${Join<Extract<R, string[]>, D>}`
    : never
  : string;

export type TranslationPaths<TranslationMap extends Record<string, any>> = Join<
  PathsToStringProps<TranslationMap>,
  "."
>;
export type TranslationFunction<TranslationMap extends Record<string, any>> = (
  key: TranslationPaths<TranslationMap>,
  data?: Record<string, any>
) => string;
